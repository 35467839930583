.Workspace {
  margin: auto;
  width: 65%;
  border: 1px solid green;
  border-radius: 1rem;
  padding: 10px;
  text-align: center;
  background-color: #181818;
  margin-top: 5px;
}

.rowC {
  display: flex;
  flex-direction: row;
}

@media (max-width: 800px) {
  .rowC {
    flex-direction: column;
  }
}

.TodoList {
  /* border: 3px solid red; */
  padding: 10px;
  width: 70%;
  text-align: left;
  background-color: #181818;
}

@media (max-width: 800px) {
  .TodoList {
    width: auto;
    height: 120px;
  }
}